import React from "react"
import {Layout} from "../components/Layout/layout";
import {PageTitle} from "../components/PageTitle";
import {Container} from "reactstrap";

export default () => (
    <React.Fragment>
        <Layout pageName="calendarOfEvents">
            <PageTitle title="Calendar of Events"/>
            <Container>
                <iframe
                    title="Calendar of Events"
                    src="https://calendar.google.com/calendar/embed?src=ra4rfcitndj6hapcrgl229tivk%40group.calendar.google.com&ctz=America%2FVancouver"
                    style={{borderWidth: 0}}
                    width="800"
                    height="600"
                    frameBorder="0"
                    scrolling="no">
                </iframe>
            </Container>
        </Layout>
    </React.Fragment>
)
